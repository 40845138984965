<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
  <div class="logo">
    <img alt="Logo" width="48" height="22" class="logo-icon" src="images/logos/wit-con-logo.png"/>
    <div class="user-container logo-text">
      <div class="company-name">WIT-CON GmbH</div>
      <div class="job-title">{{ slogan }}</div>
    </div>
  </div>

  <div class="buttons">
    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
      <mat-icon class="secondary-text">menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>
  </div>
</div>
<mat-divider class="dark-divider"></mat-divider>
<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
  @if (currentUser | async; as user) {
    <div
      class="user cursor-pointer"
      fxLayout="column"
      [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
      [routerLink]="['profile', 'general', user.id]">
      <div class="h3 username">
        {{ user.displayName }}
      </div>
      @if (switchedUser) {
        <button mat-flat-button color="primary" type="button" (click)="$event.stopPropagation(); switchBack()">Wechseln zu
          {{ switchedUserName }}
        </button>
      }
      <div class="h5 email hint-text mt-8 text-center">
        <!--{{ authService.currentUserValue.userRoles.role }}-->
      </div>
      <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
        <omt-avatar [size]="isFolded ? 40 : 72" [avatarUrl]="user.avatarUrl"></omt-avatar>
      </div>
    </div>
  }

  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
</div>
